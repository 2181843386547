import React from "react"

const getInitialPage = () => {

    const defaultValue = 'home';

    if(typeof window === 'undefined') return defaultValue;

    const pathname = window.location.pathname;
    const regex = /^\/([^/]*)(?:\/.*)?$/;
    const found = pathname.match(regex);

    if(found.length < 2 || found[1] === '') {
        return defaultValue;
    } else {
        return found[1];
    }

}

const defaultContextValue = {
    data: {
        // set your initial data shape here
        currentPage: getInitialPage(),
    },
    set: () => {},
}

const context = React.createContext(defaultContextValue)
const { Provider, Consumer } = context

class ContextProviderComponent extends React.Component {
    constructor() {
        super();

        this.setData = this.setData.bind(this);
        this.state = {
            ...defaultContextValue,
            set: this.setData,
        }
    }

    setData(newData) {
        this.setState(state => ({
            data: {
                ...state.data,
                ...newData,
            },
        }));
    }

    render() {
        return <Provider value={this.state}>{this.props.children}</Provider>;
    }
}
export { Provider, Consumer, context }
export { Consumer as default }
export { ContextProviderComponent }
