import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Ancillary from "./ancillary"
import Footer from "./footer"
import Blob from "./blob"
import { ContextProviderComponent } from "./context"

export default function Layout({ children }) {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    author, email, telephone, title
                    headerLinks {
                        title
                        path
                    }
                    footerLinks {
                        title
                        path
                    }
                    services {
                        title
                        path
                    }
                    platforms {
                        title
                        path
                    }
                }
            }
        }
    `);

    const telephoneLink = () => {
        return `tel:+44${data.site.siteMetadata.telephone.replace(/\s/g, '').substring(1)}`;
    };
    
    const emailLink = () => {
        return `mailto:${data.site.siteMetadata.email}`
    }

    return (
        <ContextProviderComponent>
            <Header headerLinks={data.site.siteMetadata.headerLinks}
                    email={data.site.siteMetadata.email}
                    emailLink={emailLink()}
                    siteTitle={data.site.siteMetadata.title}
                    telephone={data.site.siteMetadata.telephone}
                    telephoneLink={telephoneLink()}
            />

            <Blob/>

            <div className={"relative"}>
                <main>{children}</main>
            </div>

            <Ancillary email={data.site.siteMetadata.email}
                       emailLink={emailLink()}
                       services={data.site.siteMetadata.services}
                       platforms={data.site.siteMetadata.platforms}
                       telephone={data.site.siteMetadata.telephone}
                       telephoneLink={telephoneLink()}
            />

            <Footer footerLinks={data.site.siteMetadata.footerLinks}
                    siteAuthor={data.site.siteMetadata.author}
                    siteTitle={data.site.siteMetadata.title}/>
        </ContextProviderComponent>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};
