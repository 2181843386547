import React from 'react';
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { context } from './context';

if (typeof window !== `undefined`) {
    gsap.registerPlugin(MotionPathPlugin);
}

class Blob extends React.Component {

    constructor(props){
        super(props);

        const r = 15;

        if (typeof window !== `undefined`) {
            this.blobTimeline = gsap.timeline();
            this.blobPathTimeline = gsap.timeline();
        }

        this.blobPaths = [];
        this.blob = undefined;
        this.blobGentleHover = {
            motionPath: {
                path: `M ${-r}, 0
                a ${r},${r} 0 1,0 ${r * 2},0
                a ${r},${r} 0 1,0 -${r * 2},0z`,
                align: 'self'
            },
            duration: 10,
            repeat: -1,
            ease: 'none'
        };
        this.blobPathPositions = {
            about: {
                rotation: -60,
                yPercent: 15,
                xPercent: -50,
            },
            services: {
                rotation: 120,
                yPercent: 10,
                xPercent: 140,
            },
            projects: {
                rotation: 120,
                yPercent: 10,
                xPercent: -50,
            },
            contact: {
                rotation: 190,
                yPercent: -60,
                xPercent: -40,
            },
            home: {
                rotation: -30,
                yPercent: -20,
                xPercent: 120,
            }
        }

        this.setBlobPosition = (currentPage, mergeObject = {}) => {
            let blobPosition = this.blobPathPositions[currentPage] || this.blobPathPositions['home'];
            return Object.assign(blobPosition, mergeObject);
        }

        this.onPortable = () => {
            return (Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < 1024);
        }
    }

    componentDidMount(){
        if(!this.onPortable()) {
            // Set the blog paths into the correct position for the page
            gsap.set(this.blobPaths, this.setBlobPosition(this.context.data.currentPage));
        }

        const blobWrapper = document.querySelector('.blob-wrapper')

        if (blobWrapper) {
            blobWrapper.classList.remove('opacity-0')
            blobWrapper.classList.add('opacity-1')
        }

        // Gently move the blob in a circle
        this.blobTimeline.to(this.blob, this.blobGentleHover);

    }

    componentDidUpdate() {
        if (this.onPortable()) return;
    
        // Set the animation destination
        const animDestination = this.setBlobPosition(this.context.data.currentPage, {
            duration: 1,
            stagger: {
                each: 0.5,
                ease: 'circ.inOut'
            },
        });
    
        // Completely reset the timeline to avoid reuse issues
        if (this.blobPathTimeline) this.blobPathTimeline.kill();
        this.blobPathTimeline = gsap.timeline();
    
        // Animate the blob paths
        setTimeout(() => {
            this.blobPathTimeline.to(this.blobPaths, animDestination);
        }, 1000)
    }
    
    render() {

        return (
            <div className="absolute w-100 md:top-0 md:right-auto md:left-0 blob-container overflow-visible -z-10">
                <div ref={div => this.blob = div} className="absolute blob-wrapper overflow-visible opacity-0 transition-opacity duration-200">
                    <svg ref={svg => this.blobPaths.push(svg)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 350" className="blob absolute overflow-visible">
                        <path d="M150.313,327.264C179.433,324.975 204.708,302.722 223.756,282.85C249.671,255.836 260.844,239.81 275.129,204.92C287.95,173.51 294.085,135.689 277.235,103.272C260.66,71.404 226.228,50.158 194.452,37.063C183.737,32.667 159.378,26.257 159.378,26.257C134.012,20.946 107.547,20.763 83.921,29.004C48.39,41.459 25.313,73.693 22.749,111.697C20.001,151.898 39.049,192.557 53.609,229.645C58.463,242.008 63.133,254.462 67.254,267.099C78.06,300.158 108.737,330.469 150.313,327.264Z" id="blob-fill" className="blob-path"/>
                    </svg>
                    <svg ref={svg => this.blobPaths.push(svg)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 350" className="blob absolute overflow-visible">
                        <path d="M149.669,338.75C181.259,336.292 208.676,312.405 229.339,291.073C257.452,262.074 269.571,244.871 285.068,207.418C298.976,173.701 305.631,133.102 287.353,98.303C269.373,64.094 232.021,41.288 197.55,27.231C185.928,22.513 159.503,15.631 159.503,15.631C131.986,9.93 103.277,9.733 77.648,18.581C39.104,31.95 14.071,66.552 11.289,107.347C8.309,150.502 28.972,194.148 44.767,233.96C50.032,247.231 55.098,260.6 59.568,274.165C71.29,309.652 104.569,342.19 149.669,338.75Z" id="blob-outline-1" className="blob-path"/>
                    </svg>
                    <svg ref={svg => this.blobPaths.push(svg)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 350" className="blob absolute overflow-visible">
                        <path d="M149.517,349.732C183.579,347.105 213.141,321.569 235.42,298.765C265.732,267.764 278.8,249.373 295.509,209.335C310.505,173.29 317.681,129.889 297.973,92.687C278.586,56.117 238.312,31.737 201.145,16.709C188.613,11.665 160.121,4.309 160.121,4.309C130.452,-1.786 99.497,-1.997 71.862,7.461C30.303,21.753 3.311,58.744 0.312,102.356C-2.901,148.489 19.378,195.148 36.408,237.709C42.085,251.895 47.548,266.187 52.368,280.69C65.007,318.626 100.889,353.41 149.517,349.732Z" id="blob-outline-2" className="blob-path"/>
                    </svg>
                </div>
            </div>
        )

    }
}

Blob.contextType = context;

export default Blob
