import React from "react";
import { useEffect, useRef } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import { motion, useAnimation, useInView } from "framer-motion";

import BookCall from "./BookCall";

const Ancillary = ({ email, emailLink, services, telephone, telephoneLink, platforms }) => {
    
    const footerLinks = [
        {
            title: "services",
            links: services,
        },
        {
            title: "platforms",
            links: platforms
        },
        {
            title: "contact",
            links: [
                {
                    path:  emailLink,
                    title:  email,
                },
                {
                    path: telephoneLink,
                    title: telephone, 
                }
            ]
        }
    ]

    const ctaButtonClasses = 'flex-1 text-center whitespace-no-wrap px-5 py-3 rounded-md text-white focus:outline-none focus:ring transition duration-150 ease-in-out'
    const linkTitleClasses = 'text-secondary leading-8 sm:leading-10 text-base font-display font-bold uppercase'

    const ancillaryCTA = useRef(null)
    const ancillaryText = useRef(null)
    const isCTAInView = useInView(ancillaryCTA)
    const ancillaryTextInView = useInView(ancillaryText)
  
    const animationCTA = useAnimation()
    const animateAncillaryText = useAnimation()

    useEffect(() => {
        if (isCTAInView) {
          animationCTA.start('visible')
        }
        if (ancillaryTextInView) {
            animateAncillaryText.start('visible')
        }
    }, [isCTAInView, animationCTA, ancillaryTextInView, animateAncillaryText])
    
    
    return (
        <aside className="border-t-2 bg-white">
            <motion.div className="container mx-auto py-12 lg:flex lg:items-center lg:justify-between lg:gap-12"
                ref={ancillaryCTA}
                variants={{
                    hidden: {opacity: 0, y: 75},
                    visible: {opacity: 1, y: 0},
                }}
                initial= 'hidden'
                animate={animationCTA}
                transition={{
                    duration: 0.5,
                    delay: 0.5
                }}
            >
                <div>
                    <p className="text-3xl md:text-4xl">
                        Ready to talk?
                    </p>
                    <p className="text-3xl md:text-4xl text-indigo-600 text-pretty">
                        Book a call with an AWS cloud specialist today.
                    </p>
                </div>
                <div className="lg:w-1/5 mt-8 lg:mt-0">
                    <div className="grid grid-cols-2 gap-3 lg:grid-cols-1">
                        <BookCall />

                        <Link to="/contact" className={`${ctaButtonClasses} bg-secondary hover:bg-orange-500`}>
                            Contact us
                        </Link>
                    </div>
                </div>
            </motion.div>

            <div className="bg-primary bg-opacity-75">
                <motion.div
                    ref={ancillaryText}
                    variants={{
                        hidden: {opacity: 0, y: 75},
                        visible: {opacity: 1, y: 0},
                    }}
                    initial= 'hidden'
                    animate={animateAncillaryText}
                    transition={{
                        duration: 0.5,
                        delay: 0.5
                    }}
                >
                    <div className="container py-12 lg:py-16 grid grid-cols-1 lg:grid-cols-2 lg:gap-12 xl:gap-20">
                        <div className="mb-6 md:mb-8 lg:mb-0">
                            <h2 className={linkTitleClasses}>
                                About
                            </h2>
                            <p className="text-white prose prose-lg text-balance">
                                Think of Opworks as an extension of your own IT team for all things AWS cloud. We will design, build and maintain the foundations your organisation needs in the AWS cloud to compete with the best in your field. Our aim is to deliver a solid infrastructure that's resilient, secure and scalable, whilst also reducing costs and improving the metrics that matter to you.
                            </p>
                        </div>

                        <ul className="flex flex-col gap-y-4 md:flex-wrap md:gap-x-4 lg:flex-row gap-x-8 lg:justify-between">
                            {footerLinks.map((linkGroup, linkGroupIndex) => {
                                return (
                                    <li key={linkGroupIndex}>
                                        <h3 className={linkTitleClasses}>
                                            {linkGroup.title}
                                        </h3>    

                                        <ul className="footer-links">
                                            {linkGroup.links.map((link, index) => {
                                                const linkClasses = "text-white text-lg hover:text-secondary focus:text-secondary whitespace-nowrap"
                                                
                                                return (
                                                    <li key={index}>
                                                        {link.path.startsWith("/") ? (
                                                            <Link to={link.path} className={`${linkClasses} footer-link`}>
                                                                {link.title}
                                                            </Link>
                                                        ) : (
                                                            <a href={link.path} className={linkClasses}>
                                                                {link.title}
                                                            </a>
                                                        )}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </motion.div>
            </div>
        </aside>
    )
}

Ancillary.propTypes = {
    email: PropTypes.string,
    emailLink: PropTypes.string,
    telephone: PropTypes.string,
    telephoneLink: PropTypes.string
}

export default Ancillary
