import React, { useEffect, useRef } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import { motion, useAnimation, useInView } from "framer-motion";

let currentYear = new Date().getFullYear();

const Footer = ({ footerLinks, siteAuthor, siteTitle }) => {

    const footerRef = useRef(null)
    const footerInView = useInView(footerRef)
    const animateFooter = useAnimation()

    useEffect(() => {
        if (footerInView) {
            animateFooter.start('visible')
        }
    }, [footerInView, animateFooter])


    return (
        <motion.footer className="container py-12 lg:py-16 grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-12 text-gray-700"
            ref={footerRef}
            variants={{
                hidden: {opacity: 0},
                visible: {opacity: 1},
            }}
            initial= 'hidden'
            animate={animateFooter}
            transition={{
                duration: 0.5,
                delay: 0.5
            }}
        >
            <div className="max-md:border-b max-md:border-gray-300 max-md:pb-5 lg:text-left max-w-lg">
                <p className="leading-loose max-md:text-center text-xs xl:text-sm text-balance">
                    Copyright {currentYear} {siteTitle}. All rights reserved. Web Design by <span>
                        <a 
                            href="https://www.enovate.co.uk" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            {siteAuthor}
                        </a>.
                    </span> "Opworks" is a trading style of Enovate Design Ltd.
                </p> 
            </div>

            <nav className="flex items-center max-md:justify-center md:justify-end">
                <ul className="flex max-sm:flex-wrap max-sm:justify-center gap-x-4 items-center">
                    {footerLinks.map((link, index) => (
                        <li key={index}>
                            <Link to={link.path} className="text-xs xl:text-sm">
                                {link.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </motion.footer>
    )
}

Footer.propTypes = {
    siteAuthor: PropTypes.string,
    siteTitle: PropTypes.string,
}

export default Footer
