import React, { useRef } from "react";
import { PopupButton } from "react-calendly";

const BookCall = () => {
    const buttonClasses = 'w-full text-center whitespace-no-wrap px-5 py-3 rounded-md text-white focus:outline-none focus:ring transition duration-150 ease-in-out bg-indigo-600 hover:bg-indigo-700 whitespace-nowrap no-underline'
    const root = useRef()
  
    return (
        <div ref={root} className="">
            <PopupButton
                url="https://calendly.com/mtwalsh/30min"
                rootElement={root.current}
                text="Book a call now"
                className={buttonClasses}
            />
        </div>
    )
}

export default BookCall