import React from "react"
import { Link } from "gatsby"
import { gsap } from "gsap"

import ContextConsumer from "./context"

class Header extends React.Component {
    constructor(props) {
        super(props);

        if (typeof window !== `undefined`) {
            this.circlesTimeline = gsap.timeline();
        }

        this.siteTitle = props.siteTitle;
        this.telephoneLink = props.telephoneLink;
        this.telephone = props.telephone;
        this.siteTitle = props.siteTitle;
        this.email = props.email;
        this.headerLinks = props.headerLinks;
        this.onPortable = () => {
            return (Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < 1024);
        }

        this.state = {isExpanded: false};
        this.toggleExpansion = value => {
            this.setState({
                isExpanded: value
            });
        }

        this.circles = [];
        this.delayedCall = false;
        this.delayedTouchCall = false;

        this.onHoverOver = (e) => {
            if(this.onPortable()) return;

            const targetButtonWidth = e.target.offsetWidth;
            const targetButtonLeft = e.target.offsetLeft;
            const hoverBlobWidth = 121;
            const hoverBlobWidthAdjuster = 53;
            const blobOffset = ((targetButtonWidth - hoverBlobWidth) / 2) - hoverBlobWidthAdjuster;

            if (typeof this.delayedCall.kill == 'function') {
                this.delayedCall.kill();
            }

            if (typeof this.delayedTouchCall.kill == 'function') {
                this.delayedTouchCall.kill();
            }

            this.circlesTimeline.clear();
            this.circlesTimeline.to(this.circles, {
                x: targetButtonLeft + blobOffset,
                y: 29,
                opacity: 1,
                duration: .3,
                stagger: {
                    from: 'random',
                    each: 0.1,
                    ease: 'circ.inOut'
                }
            });

            this.delayedTouchCall = gsap.delayedCall(2, this.doHoverOut);

        };

        this.onHoverOut = (e) => {
            if(this.onPortable()) return;

            this.delayedCall = gsap.delayedCall(1, this.doHoverOut);

        };

        this.doHoverOut = () => {
            this.circlesTimeline.clear();
            this.circlesTimeline.to(this.circles, {
                opacity: 0,
                duration: .25,
                ease: 'circ.out'
            }).set(this.circles, {
                x: '+=random(-100, 100)',
                y: '+=random(-100, 100)'
            });

            if (typeof this.delayedTouchCall.kill == 'function') {
                this.delayedTouchCall.kill();
            }

        };
    }

    componentDidMount() {
        if(this.onPortable()) return;

        // Get the viewport width
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

        // Hide the circles to start with and position them randomly
        gsap.set(this.circles, {
            opacity: 0,
            x: (vw * 0.75 + (gsap.utils.random(-300, 300))),
            y: 'random[-100, 100]'
        });
    }

    render() {

        return (
            <header className="relative z-20 py-3 lg:py-8 px-6 md:px-12 mb-8 header">
                <nav className="flex items-center justify-between flex-wrap">
                    <div className="flex items-center justify-between w-full lg:w-auto">
                        <h1 className="logo">
                            <ContextConsumer>{({ data, set }) => (
                            <Link to="/" title="Return to homepage"
                                  className="block overflow-hidden whitespace-no-wrap bg-no-repeat bg-contain w-full h-full"
                                  onClick={() => set({ currentPage: 'home' })}>{this.siteTitle}</Link>
                            )}</ContextConsumer>
                        </h1>

                        <button className={`block relative outline-none w-8 h-5 lg:hidden hamburger ${this.state.isExpanded ? 'active' : ''}`}
                                aria-controls="navbarSupportedContent" aria-expanded={this.state.isExpanded} aria-label="Toggle navigation" title="Navigation menu"
                                onClick={() => this.toggleExpansion(!this.state.isExpanded)}>
                            <span className="block absolute transition duration-150 bg-gray-600 w-full"></span>
                            <span className="block absolute transition duration-150 bg-gray-600 w-full"></span>
                            <span className="block absolute transition duration-150 bg-gray-600 w-full"></span>
                        </button>
                    </div>

                    <div id="navbarSupportedContent"
                        className={`${this.state.isExpanded ? `block pt-5` : `hidden`} flex flex-col w-full lg:flex lg:flex-col-reverse lg:p-0 lg:w-auto`}>
                        <ContextConsumer>{({ data, set }) => (
                            <ul className="flex flex-col lg:flex-row primary-navigation">
                                {this.headerLinks.map((link, index) => (
                                    <li className="lg:ml-10" key={index}>
                                        <Link to={link.path}
                                              className="block relative py-2 text-md uppercase font-bold text-indigo-600 hover:text-secondary focus:text-secondary transition-colors duration-300 lg:border-0 lg:px-0 lg:pt-0"
                                              activeClassName="text-secondary"
                                              partiallyActive={index === 0 ? false : true}
                                              data-index={index}
                                              onClick={() => {
                                                  set({ currentPage: link.title.toLowerCase() });
                                                  this.toggleExpansion(!this.state.isExpanded);
                                              }}
                                              onMouseOver={this.onHoverOver}
                                              onMouseOut={this.onHoverOut}
                                        >{link.title}</Link>
                                    </li>
                                ))}
                            </ul>
                        )}</ContextConsumer>

                        <ul className="flex flex-col pt-3 text-center xs:flex-row xs:justify-center lg:justify-end lg:mb-8 lg:pt-0 xl:mb-10">
                            <li className="xs:mx-4 lg:m-0">
                                <a href={this.telephoneLink}
                                   className="text-gray-700 lg:text-lg xl-text-xl hover:text-indigo-600">{this.telephone}</a>
                            </li>
                            <li className="xs:mx-4 lg:mr-0 lg:ml-12 xl:ml-16">
                                <a href={`mailto:${this.email}`}
                                   className="text-gray-700 lg:text-lg xl-text-xl hover:text-indigo-600">{this.email}</a>
                            </li>
                        </ul>

                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" id="hoverBlob"
                             className="hidden lg:block absolute top-0 left-0 w-full overflow-visible">
                            <defs>
                                <filter id="goo">
                                    <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10"/>
                                    <feColorMatrix in="blur" mode="matrix"
                                                   values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                                                   result="goo"/>
                                    <feBlend in2="goo" in="SourceGraphic" result="mix"/>
                                </filter>
                            </defs>
                            <mask id="opw-mask">
                                <g className="header-blobs">
                                    <g className="header-blobs">
                                        <circle ref={circle => this.circles.push(circle)} className="header-blob" cx="144" cy="85" r="28"
                                                transform="rotate(0) translate(0, 0) rotate(0)"/>
                                        <circle ref={circle => this.circles.push(circle)} className="header-blob" cx="78" cy="86" r="25"
                                                transform="rotate(0) translate(0, 0) rotate(0)"/>
                                        <circle ref={circle => this.circles.push(circle)} className="header-blob" cx="118" cy="78" r="36"
                                                transform="rotate(0) translate(0, 0) rotate(0)"/>
                                        <circle ref={circle => this.circles.push(circle)} className="header-blob" cx="98" cy="92" r="24"
                                                transform="rotate(0) translate(0, 0) rotate(0)"/>
                                    </g>
                                </g>
                            </mask>
                            <rect id="opw-header-blob-rect" x="0" y="0" mask="url(#opw-mask)" fill="url(#opw-gradient)" width="100%"
                                  height="400" className="pointer-events-none"/>
                        </svg>
                    </div>

                </nav>

            </header>
        )
    }
}

export default Header
